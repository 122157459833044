<template>
    <fieldset>
        <validation-observer
            ref="VFormRekening"
        >
            <div class="row">
                <div class="col-md-4">
                    <div class="va_wrapper">
                        <p>Virtual Account</p>
                        <div class="d-flex mb-1" v-for="(v,k) in isParent.row.dp_virtual_account" :key="k">
                            <span>
                            {{v}}
                            </span>
                            <button type="button" @click="removeVA(k)" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                    <div class="form-group mt-2">
                        <input type="text" v-model="newVA" @keyup="isParent.$parent.onlyNumDeb" @keydown="isParent.$parent.onlyNumDeb" name="name" class="form-control" placeholder="Tambah Virtual Account">
                        <VValidate 
                            :name="'Virtual account'" 
                            v-model="newVA" 
                            :rules="{min:3, max: 16, numeric: 1}"
                        />
                    </div>
                    
                    <a href="javascript:;" @click="addVA()" data-toggle="modal" data-target="#add_va"
                        class="btn bg-blue-400 btn-block legitRipple"><i
                            class="icon-plus2 mr-2 "></i> Tambah Virtual Account</a>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-7 wrap_wizard_add bor_left">
                    <div class="table-responsive mb-2">
                        <table class="table table-bordered table-bordered">
                            <thead>
                                <tr>
                                    <th>Nama Bank</th>
                                    <th>Nama Pemilik Akun</th>
                                    <th>No Rekening</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody v-if="(isParent.$parent.dbMR||[]).length">
                                <tr v-for="(v,k) in isParent.$parent.dbMR" :key="k">
                                    <td>
                                        <div class="result_tab">
                                            <p>{{v.db_nama_bank}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                            <p>{{v.db_nama_pemilik}}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                            <p>{{v.db_no_rekening}}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                            <button type="button" @click="removeBank(k)" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4">Tidak ada Data Bank</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row mt-4" v-if="isShowBank">
                            
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" @input="removeMultiSpaceDeb($event,'db_nama_bank','newBank')" @keyup="removeWildCharDeb" @keydown="removeWildCharDeb" v-model="newBank.db_nama_bank" name="name" class="form-control" placeholder="Nama Bank">
                          
                                    <VValidate 
                                        name="Nama Bank" 
                                        v-model="newBank.db_nama_bank" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" @input="removeMultiSpaceDeb($event,'db_nama_pemilik','newBank')" @keyup="removeWildCharDeb" @keydown="removeWildCharDeb" v-model="newBank.db_nama_pemilik" name="name" class="form-control" placeholder="Nama Pemilik Akun">
                                    
                                    <VValidate 
                                        name="Nama Pemilik Akun" 
                                        v-model="newBank.db_nama_pemilik" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" @input="removeMultiSpaceDeb($event,'db_no_rekening','newBank')" @keyup="removeWildCharDeb" @keydown="removeWildCharDeb" v-model="newBank.db_no_rekening" name="name" class="form-control" placeholder="No Rekening">

                                    <VValidate 
                                        name="No Rekening" 
                                        v-model="newBank.db_no_rekening" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, required:1, max: 60, numeric:1}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="btn-group mb-3">
                                    <a href="javascript:;" @click="addBank" data-toggle="modal" data-target="#add_bank"
                                        class="btn bg-blue-400  legitRipple"><i
                                            class="icon-plus2 mr-2 "></i> Add Bank </a>
                                    <a href="javascript:;" @click="isShowBank = false" data-toggle="modal" data-target="#add_bank"
                                        class="btn bg-blue-400 ml-2 legitRipple"><i
                                            class="icon-cross ml-2 "></i> Batal </a>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-4" v-else>
                            <div class="col-md-6">
                                <div class="btn-group mb-3">
                                    <a href="javascript:;" @click="isShowBank = true" data-toggle="modal" data-target="#add_bank"
                                        class="btn bg-blue-400  legitRipple"><i
                                            class="icon-plus2 mr-2 "></i> Tambah Bank </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        data(){
            return {
                isNextStep : false,
                isAddBank: false,
                isShowBank: false,
                newVA: "",
                newBank : {
                    db_debitur_type: 'CORPORATE',
                    db_nama_bank : "",
                    db_nama_pemilik : "",
                    db_no_rekening : ""
                }
            }
        },
        computed: {
            isParent() { return this.$parent.$parent }
        },
        mounted() {
            this.$emit('can-continue', {value: true})
        },
        methods: {
            removeMultiSpaceDeb(e,fields,row = 'row'){
                var val = e.target.value.replace(/\s\s+/g, ' ')
                this[row][fields] = val
            },
            removeWildCharDeb(e){
                let regex = /^[^[\]{}*!?^%#\/^/\\#?]*$/
                if (!regex.test(e.target.value)) {
                    return e.target.value = e.target.value.slice(0, -1)
                }
            },
            addVA(){
                if(this.newVA.length > 2){
                    this.isParent.row.dp_virtual_account.push(this.newVA)
                    this.newVA = ""
                }
            },
            removeVA(k){
                this.isParent.row.dp_virtual_account.splice(k,1)      
            },
            addBank(){
                this.$refs['VFormRekening'].validate().then(success => {
                    if(success){
                        this.newBank.db_debitur_id = this.isParent.row.dp_id||"add"
                        this.isParent.$parent.dbMR.push(this.newBank)
                        
                        this.newBank = {
                            db_debitur_type : 'CORPORATE', 
                            db_debitur_id : this.isParent.row.dp_id||"add",
                            db_nama_bank : "",
                            db_nama_pemilik : "",
                            db_no_rekening : ""
                        }
                        this.isShowBank = false
                    }
                })
            },
            removeBank(k){
                this.isParent.$parent.dbMR.splice(k,1)      
            }
        },
    }
</script>