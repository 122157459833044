<template>
    <fieldset>
        <validation-observer
            ref="VFormInfo"
        >
        <div class="wrap_wizard_add">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nomor Identitas Badan Usaha</label>
                        <input type="text" name="name" @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_nomor_identitas_bu')" v-model="isParent.row.dp_nomor_identitas_bu" class="form-control" placeholder="No Identitas Badan Usaha">
                        <VValidate 
                            name="Nomor Identitas Badan Usaha" 
                            v-model="isParent.row.dp_nomor_identitas_bu" 
                            :rules="{numeric:1, min:13, max: 13}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Bentuk Badan Usaha<small class="txt_mandatory">*</small></label>
                        <v-select @search="searchCodeBU($event)" @open="clearCodeBU" v-model="isParent.row.dp_bentuk_bu" placeholder="Pilih Badan Usaha" :options="isParent.$parent.mrCodeBU" label="text" :clearable="true" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                   Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Badan Usaha.</span>
                            </template>
                        </v-select>
                        <VValidate 
                            name="Bentuk Badan Usaha" 
                            v-model="isParent.row.dp_bentuk_bu" 
                            :rules="{required:1}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nama Badan Usaha<small class="txt_mandatory">*</small></label>
                        <input type="text" name="name" v-model="isParent.row.dp_nama_bu" class="form-control" placeholder="cth: PT Mandiri Sentosa">
                        <VValidate 
                            name="Nama Badan usaha" 
                            v-model="isParent.row.dp_nama_bu" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tempat Pendirian</label>
                        <input type="text" name="name" class="form-control" v-model="isParent.row.dp_tmp_berdiri" placeholder="cth: Jakarta Barat">
                        <VValidate 
                            name="Tempat Pendirian" 
                            v-model="isParent.row.dp_tmp_berdiri" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nomor Akta Pendirian</label>
                        <input type="text" name="name" @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_akta_berdiri')" class="form-control" v-model="isParent.row.dp_akta_berdiri" placeholder="cth: Jakarta Barat">
                        <VValidate 
                            name="Nomor Akta Pendirian" 
                            v-model="isParent.row.dp_akta_berdiri" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tanggal Akta Pendirian</label>
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent" placeholder="Tanggal Akta Pendirian"
                            class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_tgl_akta_pendirian"
                            >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nomor Akta Perubahan Terakhir</label>
                        <input type="text" v-model="isParent.row.dp_no_akta_perubahan" name="name" class="form-control" placeholder="cth: Jakarta Barat">
                       
                        <VValidate 
                            name="Nomor Akta Perubahan Terakhir" 
                            v-model="isParent.row.dp_no_akta_perubahan" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tanggal Akta Perubahan Terakhir</label>
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent" placeholder="Tanggal Akta Perubahan Terakhir"
                            class="my-datepicker" calendar-class="my-datepicker_calendar" 
                            v-model="isParent.row.dp_tgl_akta_perubahan"
                            >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Bidang Usaha</label>
                        <v-select placeholder="Pilih Bidang Usaha" v-model="isParent.row.dp_bu" @open="clearBU" @search="searchBU($event)" :options="isParent.$parent.mrBU" label="text" :clearable="true" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                   Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Badan Usaha.</span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <!--
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Jenis Usaha</label>
                        <v-select placeholder="Pilih Jenis Usaha" 
                        @search="searchJU($event)" v-model="isParent.row.dp_jenis_bu" :options="isParent.$parent.mrJU" label="text" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                   Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <em v-else style="opacity: 0.5">Masukkan 3 huruf untuk mencari jenis usaha.</em>
                            </template>
                        </v-select>
                    </div>
                </div>
                -->
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Jumlah Karyawan Tetap
                                </label>
                                <input type="number" @input="countKaryawan()" name="name" v-model="isParent.row.dp_karyawan_ttp" class="form-control" placeholder="e.g. 50">
                            </div>

                            <VValidate 
                                name="Jumlah Karyawan Tetap" 
                                v-model="isParent.row.dp_karyawan_ttp" 
                                :rules="{regex:/^[0-9]+$/, min_value:1}"
                            />
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Jumlah Karyawan Tidak Tetap</label>
                                <input type="number" @input="countKaryawan()" name="name" v-model="isParent.row.dp_karyawan_tdk_ttp" class="form-control" placeholder="e.g. 30">
                            </div>
                            
                            <VValidate 
                                name="Jumlah Karyawan Tidak Tetap" 
                                v-model="isParent.row.dp_karyawan_tdk_ttp" 
                                :rules="{regex:/^[0-9]+$/, min_value:1}"
                            />
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Total Karyawan</label>
                                <input type="number" :disabled="true" name="name" v-model="isParent.row.dp_total_karyawan" class="form-control" placeholder="e.g. 80">
                            </div>
                            
                            <VValidate 
                                name="Total Karyawan" 
                                v-model="isParent.row.dp_total_karyawan" 
                                :rules="{integer: 1}"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Golongan Debitur </label>
                        <v-select placeholder="Pilih Golongan Debitur" @open="clearGD" v-model="isParent.row.dp_gol_debitur" @search="searchGD" :options="isParent.$parent.mrGolDeb" label="text" :clearable="true" :reduce="v=>v.value">\
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                    Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Golongan Debitur.</span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nama Grup Debitur</label>
                        <input type="text" v-model="isParent.row.dp_grup_debitur"  class="form-control">
                        <VValidate 
                            name="Nama Grup Debitur" 
                            v-model="isParent.row.dp_grup_debitur" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                <!--
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nomor Debitur</label>
                        <input type="text" v-model="isParent.row.dp_no_debitur"  class="form-control">
                        <VValidate 
                            name="Nomor Debitur" 
                            v-model="isParent.row.dp_no_debitur" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                -->
                <div class="col-md-4" v-if="!user.isIDOfficer">
                    <div class="form-group">
                        <label for="">Pilih ID Officer <small class="txt_mandatory">*</small></label>
                        <v-select @search="searchIDOfficer($event)" @open="clearIDOfficer" v-model="isParent.row.dp_id_officer" placeholder="Pilih ID Officer" 
                        :disabled="isParent.$parent.pageSlug !== 'add'" :options="isParent.$parent.mrIDOfficer" label="text" :clearable="true" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                   Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari ID Officer.</span>
                            </template>
                        </v-select>
                        <VValidate 
                            name="ID Offier" 
                            v-model="isParent.row.dp_id_officer" 
                            :rules="{required: 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="heading_line">
            <h4>Informasi Kontak Perusahaan</h4>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>No Telepon </label>
                    <div class="input-group">
                        <span class="input-group-prepend">
                            <span class="input-group-text">+62</span>
                        </span>
                        <input type="text" class="form-control" placeholder="+99-999-9999-9999" v-model="isParent.row.dp_kontak_telp">
                    </div>
                    <VValidate 
                        name="No Telepon" 
                        v-model="isParent.row.dp_kontak_telp" 
                        :rules="{numeric: 1, min:3, max: 13}"
                    />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>No Telepon Seluler</label>
                    <div class="input-group">
                        <span class="input-group-prepend">
                            <span class="input-group-text">+62</span>
                        </span>
                        <input type="text" class="form-control" placeholder="+99-999-9999-9999" v-model="isParent.row.dp_kontak_cell">
                    </div>
                    <VValidate 
                        name="No Telepon Seluler" 
                        v-model="isParent.row.dp_kontak_cell" 
                        :rules="{numeric: 1, min:3, max: 13}"
                    />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Email Perusahaan</label>
                    <input type="email" name="email" class="form-control" v-model="isParent.row.dp_kontak_email" placeholder="yourcompany@email.com">
                    <VValidate 
                        name="Email Perusahaan" 
                        v-model="isParent.row.dp_kontak_email" 
                        :rules="{email: 1, min:3, max: 128}"
                    />
                </div>
            </div>
        </div>
        <div class="heading_line">
            <h4>Tempat Usaha</h4>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Provinsi</label>
                <div class="form-group">
                    <v-select placeholder="Pilih Provinsi" @input="doInputKab()" v-model="isParent.row.dp_tempat_usaha_prv" :options="isParent.$parent.mrProvince"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
            <div class="col-md-6">
                <label>Kota / Kabupaten</label>
                <div class="form-group">
                    <v-select placeholder="Pilih Kab/Kota" :options="isParent.$parent.mrCity" label="text" @input="doInputKec()" v-model="isParent.row.dp_tempat_usaha_city" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Kecamatan</label>
                <div class="form-group">
                    <v-select placeholder="Pilih Kecamatan" :options="isParent.$parent.mrDistrict" label="text" @input="doInputKel()" v-model="isParent.row.dp_tempat_usaha_kec" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
            <div class="col-md-6">
                <label>Kelurahan</label>
                <div class="form-group">
                    <v-select @input="doInputDesa()" placeholder="Pilih Kelurahan" :options="isParent.$parent.mrSDistrict" label="text" v-model="isParent.row.dp_tempat_usaha_kel" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Alamat Lengkap</label>
                    <textarea rows="3" cols="3" v-model="isParent.row.dp_tempat_usaha_alamat" class="form-control" placeholder="Alamat Lengkap"></textarea>
                    
                    <VValidate 
                        name="Alamat Lengkap" 
                        v-model="isParent.row.dp_tempat_usaha_alamat" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 128}"
                    />
                </div>
            </div>
            <div class="col-md-3">
                <label>Kode Pos</label>
                <div class="form-group">
                    <v-select @input="doInputDesa()" placeholder="Pilih Kode Pos" :options="isParent.$parent.mrPostalCode" label="text" v-model="isParent.row.dp_tempat_usaha_kode_pos" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
            </div>
        </div>
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import Datepicker from 'vuejs-datepicker'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        components:{ Datepicker },
        computed: {
            isParent() { return this.$parent.$parent }
        },
        data(){
            return {
                searchBUTxt : ""
            }
        },
        mounted() {
            console.log(this.$refs)
        },
        methods: {
            clearCodeBU(){
                this.isParent.$parent.mrCodeBU = []
                this.isParent.row.dp_bentuk_bu = null
            },
            searchCodeBU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-code-bu', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrCodeBU = res.data.data
                    })
                }
            },
            clearBU(){
                this.isParent.$parent.mrBU = []
                this.isParent.row.dp_bu = null
            },
            searchBU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-bu', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrBU = res.data.data
                    })
                }
            },
            searchJU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-ju', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrJU = res.data.data
                    })
                }else{
                    this.isParent.$parent.mrJU = []
                }
            },
            clearGD(){
                this.isParent.$parent.mrGolDeb = []
                this.isParent.row.dp_gol_debitur = null
            },
            searchGD(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-gol-deb', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrGolDeb = res.data.data
                    })
                }
            },
            searchCity(e){
                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-city', search: e, prv: this.isParent.row.dp_tempat_usaha_prv}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCity = res.data.data
                })
            },
            
            doInputKab(){
                this.isParent.$parent.mrCity = []
                this.isParent.$parent.mrDistrict = []
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []

                this.isParent.row.dp_tempat_usaha_city = null
                this.isParent.row.dp_tempat_usaha_kec = null
                this.isParent.row.dp_tempat_usaha_kel = null
                this.isParent.row.dp_tempat_usaha_kode_pos = null

                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-city', prv: this.isParent.row.dp_tempat_usaha_prv}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCity = res.data.data
                })
                
            },
            doInputKec(){
                this.isParent.$parent.mrDistrict = []
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []
                
                this.isParent.row.dp_tempat_usaha_kec = null
                this.isParent.row.dp_tempat_usaha_kel = null
                this.isParent.row.dp_tempat_usaha_kode_pos = null

                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-district', prv: this.isParent.row.dp_tempat_usaha_prv, city: this.isParent.row.dp_tempat_usaha_city}}, 'POST').then(res=>{
                    this.isParent.$parent.mrDistrict = res.data.data
                })
            },
            doInputKel(){
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []
                
                this.isParent.row.dp_tempat_usaha_kel = null
                this.isParent.row.dp_tempat_usaha_kode_pos = null
                
                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-sub-district', prv: this.isParent.row.dp_tempat_usaha_prv, city: this.isParent.row.dp_tempat_usaha_city, district: this.isParent.row.dp_tempat_usaha_kec}}, 'POST').then(res=>{
                    this.isParent.$parent.mrSDistrict = res.data.data
                })
                
                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-postal-code', prv: this.isParent.row.dp_tempat_usaha_prv, city: this.isParent.row.dp_tempat_usaha_city, district: this.isParent.row.dp_tempat_usaha_kec}}, 'POST').then(res=>{
                    this.isParent.$parent.mrPostalCode = res.data.data
                })
            },
            doInputDesa(){
                console.log("testing")
            },
            countKaryawan(){
                let ttp = parseInt(this.isParent.row.dp_karyawan_ttp||0)
                let tdttp = parseInt(this.isParent.row.dp_karyawan_tdk_ttp||0)
                this.isParent.row.dp_total_karyawan = ttp + tdttp
            },
            clearIDOfficer(){
                this.isParent.$parent.mrIDOfficer = []
                this.isParent.row.dp_id_officer = null
            },
            searchIDOfficer(e){
                if(e.length > 2){
                    Gen.apiRest('/do/'+this.modulePage, {data: {type:'select-id-officer', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrIDOfficer = res.data.data
                    })
                }
            },
        },
    }
</script>