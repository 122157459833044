<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Kode Bidang Usaha" @input="doFill" v-model="filter.type" :options="mrKodeBUSearch" @search="searchKodeBU" label="text" :clearable="true" :reduce="v=>v.value">
                      <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching && search.length > 2">
                              Tidak ada hasil untuk <em>{{ search }}</em
                              >.
                          </template>
                          <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Badan Usaha.</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusDeb" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(contact)="data">
              <template v-if="data.value.length">
                <div class="mb-2" v-for="(v,k) in data.value" :key="k">
                  <div class="d-flex align-items-center">
                      <div class="mr-2">
                          <span class="icon-user text-info"></span>
                      </div>
                      <div>
                          <a href="javascript:;" class="text-default  letter-icon-title">{{v.dkp_nama||"-"}}</a>
                      </div>
                  </div>
                  <div class="d-flex align-items-center">
                      <div class="mr-2">
                          <span class="icon-phone2 text-danger"></span>
                      </div>
                      <div>
                          <a href="javascript:;" class="text-default letter-icon-title">{{v.dkp_no_telp||"-"}}</a>
                      </div>
                  </div>
                </div>
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template
              #cell(dp_created_date) = "data"
            >
            {{ data.value | moment("DD MMMM YYYY, HH:mm")}} WIB
            </template>

            <template
              #cell(mkb_desc_id) = "data"
            >
              {{data.value||"-"}}
            </template>
            <template
              #cell(dp_status) = "data"
            >
              <span v-if="data.value=='W'" class="badge badge-flat border-orange-600 text-orange-600">Warm</span>
              <span v-else-if="data.value=='H'" class="badge bg-orange-600">Hot</span>
              <span v-else class="badge badge-success">Aktif</span>
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-pencil5"></i></router-link>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._
import Gen from '@/libs/Gen.js'


export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'dp_id',
      mrKodeBUSearch:[],
      mrCodeBU: [],
      mrBU: [],
      mrJU: [],
      mrGolDeb: [],

      mrProvince: [],
      mrCity: [],
      mrDistrict: [],
      mrSDistrict: [],
      mrPostalCode: [],
      
      mrProvinceP: [],
      mrCityP: [],
      mrDistrictP: [],
      mrSDistrictP: [],
      mrPostalCodeP: [],
      mrDoc: [],
      mrIDOfficer: [],    

      dpsMR: [],
      dkpMR: [],
      dapMR: [],
      ddpMR: [],
      dbMR: [],
      dpeMR: [],

      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mkb_desc_id',
          label: 'Bentuk Badan Usaha',
        },
        {
          key: 'dp_created_date',
          label: 'Tanggal',
        },
        {
          key: 'dp_nama_bu',
          label: 'Nama Perusahaan',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'dp_status',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    clearKodeBU(){
      this.filter.type = null
      this.mrKodeBUSearch = []
    },
    searchKodeBU(e){
        if(e.length > 2){
            Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-code-bu', search: e}}, 'POST').then(res=>{
                this.mrKodeBUSearch = res.data.data
            })
        }
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>