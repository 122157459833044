<template>
    <fieldset>
        <validation-observer
            ref="VFormPIC"
        >
        <div class="heading_line">
            <h4>Informasi Kontak</h4>
        </div>
        <div v-for="(v,k) in isParent.$parent.dkpMR" :key="k" class="wrap_doc_type">
            <div class="opacity-0">{{k}}</div>
            <div class="btn_delete_wrap" v-if="isParent.$parent.dkpMR.length > 1" @click="removeRow(k)">
                <button type="button" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nama PIC<small class="txt_mandatory">*</small></label>
                        <input type="text" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dkp_nama','dkpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb"  v-model="isParent.$parent.dkpMR[k]['dkp_nama']" class="form-control" placeholder="e.g. Riyan">
                        
                        <VValidate 
                            :name="'Nama PIC '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_nama']" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Departemen</label>
                        <input type="text" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dkp_department','dkpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb"  v-model="isParent.$parent.dkpMR[k]['dkp_department']" class="form-control" placeholder="e.g. Marketing">
                        
                        <VValidate 
                            :name="'Nama Departemen '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_department']" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Jabatan</label>
                        <input type="text" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dkp_jabatan','dkpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb"  v-model="isParent.$parent.dkpMR[k]['dkp_jabatan']" class="form-control" placeholder="e.g. Korporat">

                        <VValidate 
                            :name="'Jabatan '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_jabatan']" 
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                    <div class="col-md-4">
                    <div class="form-group">
                        <label>No Telepon </label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">+62</span>
                            </span>
                            <input type="text" v-model="isParent.$parent.dkpMR[k]['dkp_no_telp']" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dkp_no_telp','dkpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" class="form-control" placeholder="+99-999-9999-9999" data-mask="+99-999-9999-9999">
                        </div>
                        
                        <VValidate 
                            :name="'No Telepon '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_no_telp']" 
                            :rules="{numeric:1, min:3, max: 13}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No Telepon Seluler</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">+62</span>
                            </span>
                            <input type="text" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dkp_telp_cell','dkpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" v-model="isParent.$parent.dkpMR[k]['dkp_telp_cell']" class="form-control" placeholder="+99-999-9999-9999" data-mask="+99-999-9999-9999">
                        </div>
                        
                        <VValidate 
                            :name="'No Telepon Seluler '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_telp_cell']" 
                            :rules="{numeric:1, min:3, max: 13}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" v-model="isParent.$parent.dkpMR[k]['dkp_email']" name="email" class="form-control" placeholder="yourcompany@email.com">
                        
                        <VValidate 
                            :name="'Email '+(k+1)" 
                            v-model="isParent.$parent.dkpMR[k]['dkp_email']" 
                            :rules="{email:1, min:3}"
                        />
                    </div>
                </div>
            </div>
        </div>
        <button type="button" @click="addRow" class="btn btn-light alpha-purple text-purple-800"> <i class="icon-plus3"></i> Tambah Kontak</button>
        </validation-observer>
    </fieldset>
</template>

<script>
    export default {
        props: ['clickedNext', 'currentStep'],
        methods: {
            addRow(){
                let payload = {
                    dkp_dp_id: this.isParent.row.dp_id||"add", 
                    dkp_nama: "", 
                    dkp_department: "",
                    dkp_jabatan: "",
                    dkp_no_telp: "",
                    dkp_telp_cell: "",
                    dkp_email: ""
                }
                
                this.isParent.$parent.dkpMR.push(payload)
            },
            removeRow(k){
                this.isParent.$parent.dkpMR.splice(k,1)      
            },
        },
        computed: {
            isParent() { return this.$parent.$parent }
        }
    }
</script>