<template>
    <fieldset>
        <validation-observer
            ref="VFormDokumen"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>No NPWP Perusahaan</label>
                                <input type="text" @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_npwp')" v-model="isParent.row.dp_npwp" name="name" class="form-control" placeholder="09.254.294.3-407.000" data-mask="09.254.294.3-407.000">
                                <VValidate 
                                    name="No NPWP Perusahaan" 
                                    v-model="isParent.row.dp_npwp" 
                                    :rules="{min:3, max: 16, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="result_tab">
                                <label>File NPWP </label>
                                <Uploader isDocument v-model="isParent.row.dp_npwp_file" type="npwp"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>No SIUP</label>
                                <input type="text" v-model="isParent.row.dp_siup"
                                    @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_siup')" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" name="name" class="form-control" placeholder="09.254.294.3-407.000" data-mask="09.254.294.3-407.000">
                                
                                <VValidate 
                                    name="No SIUP" 
                                    v-model="isParent.row.dp_siup" 
                                    :rules="{min:3, max: 48, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="result_tab">
                                <label>File SIUP</label>
                                <Uploader isDocument v-model="isParent.row.dp_siup_file" type="siup"/>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Pendaftaran SIUP</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Pendaftaran SIUP"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_siup_reg_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Berakhir SIUP </label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Berakhir SIUP "
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_siup_exp_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>No TDP</label>
                                <input type="text" 
                                @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_tdp')"
                                v-model="isParent.row.dp_tdp" name="name" class="form-control" placeholder="09.254.294.3-407.000" data-mask="09.254.294.3-407.000">
                                <VValidate 
                                    name="No TDP" 
                                    v-model="isParent.row.dp_tdp" 
                                    :rules="{min:3, max: 16, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="result_tab">
                                <label>File TDP </label>
                                <Uploader isDocument v-model="isParent.row.dp_tdp_file" type="tdp"/>                                                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Pendaftaran TDP</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Pendaftaran TDP"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_tdp_reg_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Berakhir TDP</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Berakhir TDP"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_tdp_exp_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>No SK Domisili</label>
                                <input type="text"
                                @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_sk')"
                                v-model="isParent.row.dp_sk" name="name" class="form-control" placeholder="22/SD/III/2018">
                                <VValidate 
                                    name="No SK Domisili" 
                                    v-model="isParent.row.dp_sk" 
                                    :rules="{min:3, max: 16, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="result_tab">
                                <label>File SK Domisili </label>
                                <Uploader isDocument v-model="isParent.row.dp_sk_file" type="skdomisili"/>                                                                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Pendaftaran SK Domisili</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Pendaftaran SK Domisili"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_sk_reg_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Berakhir SK Domisili</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Berakhir SK Domisili"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_sk_exp_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>NIB</label>
                                <input type="text" @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_nib')" v-model="isParent.row.dp_nib" name="name" class="form-control" placeholder="9222222222">
                                <VValidate 
                                    name="No NIB" 
                                    v-model="isParent.row.dp_nib" 
                                    :rules="{min:3, max: 16, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="result_tab">
                                <label>File NIB </label>
                                <Uploader isDocument v-model="isParent.row.dp_nib_file" type="nib"/>        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Pendaftaran NIB</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Pendaftaran NIB"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_nib_reg_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tanggal Berakhir NIB</label>
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal Berakhir NIB"
                                    class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.dp_nib_exp_date"
                                    >
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Akta Pengesahan
                        </h4>
                    </div>
                </div>
                <template v-if="(isParent.$parent.dapMR||[]).length">
                    <div v-for="(v,k) in isParent.$parent.dapMR" :key="k"  class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>No Akta<small class="txt_mandatory">*</small></label>
                                            <input type="text" v-model="isParent.$parent.dapMR[k]['dap_no_akta']" name="name" class="form-control" placeholder="08">
                                          
                                            <VValidate 
                                                :name="'No Akta '+(k+1)" 
                                                v-model="isParent.$parent.dapMR[k]['dap_no_akta']" 
                                                :rules="{regex:/^[a-zA-Z0-9-/]+$/, required:1, min:15, max: 25}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>Tipe Akta<small class="txt_mandatory">*</small></label>
                                            <v-select placeholder="Pilih Alasan" v-model="isParent.$parent.dapMR[k]['dap_tipe_akta']" :options="Config.mr.tipeAkta" label="text" :reduce="v=>v.value" ></v-select>

                                            <VValidate 
                                                :name="'Tipe Akta '+(k+1)" 
                                                v-model="isParent.$parent.dapMR[k]['dap_tipe_akta']" 
                                                :rules="{required:1}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>Tanggal Akta</label>
                                            <div class="input-group mb-3">
                                                <datepicker input-class="form-control transparent" placeholder="Tanggal Akta"
                                                class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.$parent.dapMR[k]['dap_tgl_akta']"
                                                >
                                                </datepicker>
                                                <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>Notaris</label>
                                            <input type="text" v-model="isParent.$parent.dapMR[k]['dap_notaris']" name="name" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dap_notaris','dapMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" class="form-control" placeholder="e.g. Mintje Waani, S.H">
                                          
                                            <VValidate 
                                                :name="'Notaris '+(k+1)" 
                                                v-model="isParent.$parent.dapMR[k]['dap_notaris']" 
                                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="form-group">
                                            <label>No. SK KEMENKUMHAM</label>
                                            <input type="text" name="name" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dap_no_pengesahan','dapMR',k)" v-model="isParent.$parent.dapMR[k]['dap_no_pengesahan']" class="form-control" placeholder="">
                                            
                                            <VValidate 
                                                :name="'No. SK KEMENKUMHAM '+(k+1)" 
                                                v-model="isParent.$parent.dapMR[k]['dap_no_pengesahan']" 
                                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label>File Pengesahan </label>
                                            <Uploader isDocument v-model="isParent.$parent.dapMR[k]['dap_file']" type="doc_umum"/>        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Tanggal Pengesahan</label>
                                            <div class="input-group mb-3">
                                                <datepicker input-class="form-control transparent" placeholder="Tanggal Akta"
                                                class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.$parent.dapMR[k]['dap_tanggal_pengesahan']"
                                                >
                                                </datepicker>
                                                <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <button type="button" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-4"><i class="icon-plus3"></i> Tambah Pengesahan</button>
                                        <button type="button" @click="removeRow(k)" class="btn text-danger mt-4"><i class="icon-trash"></i> Hapus Pengesahan</button>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md-6">
                        <button type="button" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-4"><i class="icon-plus3"></i> Tambah Pengesahan</button>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Dokumen Pendukung
                        </h4>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6" v-for="(v,k) in isParent.$parent.ddpMR" :key="k">
                            <div class="wrap_doc_type">
                                <div class="opacity-0">{{k}}</div>
                                <div class="btn_delete_wrap">
                                    <button type="button" @click="removeRowDoc(k)" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Jenis Dokumen<small class="txt_mandatory">*</small></label>
                                            <v-select placeholder="Select Jenis Dokumen" v-model="isParent.$parent.ddpMR[k]['ddp_document']" :options="isParent.$parent.mrDoc" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            <VValidate 
                                                name="Jenis Dokumen" 
                                                v-model="isParent.$parent.ddpMR[k].ddp_document" 
                                                :rules="{required:1}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="result_tab">
                                            <label>File<small class="txt_mandatory">*</small></label>
                                            <Uploader isDocument v-model="isParent.$parent.ddpMR[k]['ddp_file']" type="doc_umum"/>        
                                            <VValidate 
                                                name="File" 
                                                v-model="isParent.$parent.ddpMR[k].ddp_file" 
                                                :rules="{required:1}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div>
                                            <label for="">Remarks</label>
                                            <textarea @input="isParent.$parent.removeMultiSpaceDebArr($event,'ddp_remarks','ddpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" v-model="isParent.$parent.ddpMR[k]['ddp_remarks']" name="" id="" rows="4" class="form-control" placeholder="e.g. Dokumen sudah terupdate"></textarea></div>
                                        <VValidate 
                                            :name="'Remarks '+(k+1)" 
                                            v-model="isParent.$parent.ddpMR[k]['ddp_remarks']" 
                                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="button" @click="addRowDoc" class="btn btn-light alpha-purple text-purple-800"> <i class="icon-plus3"></i> Tambah Dokumen</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Dokumen Pengurus
                        </h4>
                    </div>
                </div>
                <div class="col-md-12 mb-5">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nama Lengkap</label>
                                <input type="text" @input="isParent.$parent.removeMultiSpaceDeb($event,'dp_dokumen_pengurus_nama')" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" v-model="isParent.row.dp_dokumen_pengurus_nama" name="name" class="form-control" placeholder="Nama Lengkap">
                                <VValidate 
                                    :name="'Nama Lengkap'" 
                                    v-model="isParent.row['dp_dokumen_pengurus_nama']" 
                                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label>No KTP</label>
                                        <input type="text" v-model="isParent.row.dp_pengurus_no_ktp" name="name" class="form-control" placeholder="3174096112900001">
                                        
                                        <VValidate 
                                            :name="'No KTP'" 
                                            v-model="isParent.row.dp_pengurus_no_ktp" 
                                            :rules="{min:16, max: 16, numeric: 1}"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="result_tab">
                                        <label>File KTP</label>
                                        <Uploader isDocument v-model="isParent.row.dp_pengurus_file_ktp" type="doc_umum"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label>No NPWP</label>
                                        <input type="text" v-model="isParent.row.dp_pengurus_no_npwp" name="name" class="form-control" placeholder="09.254.294.3-407.000" data-mask="09.254.294.3-407.000">
                                        
                                        <VValidate 
                                            name="No NPWP" 
                                            v-model="isParent.row.dp_pengurus_no_npwp" 
                                            :rules="{min:15, max: 20, regex:/^[0-9.-]+$/}"
                                        />

                                    </div>
                                    
                                </div>
                                <div class="col-md-5">
                                    <div class="result_tab">
                                        <label>File NPWP </label>
                                        <Uploader isDocument v-model="isParent.row.dp_pengurus_file_npwp" type="doc_umum"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    // import Gen from '@/libs/Gen.js'
    import Datepicker from 'vuejs-datepicker'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        components:{ Datepicker },
        computed: {
            isParent() { return this.$parent.$parent }
        },
        data(){
            return {
                isNextStep : false
            }
        },
        methods: {
            addRow(){
                let payload = {
                    dap_dp_id: this.isParent.row.dp_id||"add", 
                    dap_no_akta: "", 
                    dap_tgl_akta: null, 
                    dap_notaris: "",
                    dap_no_pengesahan: "",
                    dap_file: "",
                    dap_tanggal_pengesahan: null
                }
                
                this.isParent.$parent.dapMR.push(payload)
            },
            removeRow(k){
                this.isParent.$parent.dapMR.splice(k,1)      
            },

            addRowDoc(){
                let payload = {
                    ddp_object_id: this.isParent.row.dp_id||"add", 
                    ddp_type: 'CORPORATE',
                    ddp_document: "",
                    ddp_file: "",
                    ddp_remarks: ""
                }
                
                this.isParent.$parent.ddpMR.push(payload)
            },
            removeRowDoc(k){
                this.isParent.$parent.ddpMR.splice(k,1)      
            }
        },
    }
</script>