<template>
<b-card no-body>
    <b-card-header>
        <b-row>
            <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
            </b-col>
        </b-row>
    </b-card-header>
    <b-card-body class="p-2">
        <b-alert v-if="$parent.row.isExpNIB" variant="danger" show dismissible>
            <b>Dokumen NIB Telah Kadaluwarsa</b>
        </b-alert>
        <b-alert v-if="$parent.row.isExpSIUP" variant="danger" show dismissible>
            <b>Dokumen SIUP Telah Kadaluwarsa</b>
        </b-alert>
        <b-alert v-if="$parent.row.isExpSK" variant="danger" show dismissible>
            <b>Dokumen SK Domisili Telah Kadaluwarsa</b>        
        </b-alert>
        <b-alert v-if="$parent.row.isExpTDP" variant="danger" show dismissible>
            <b>Dokumen TDP Telah Kadaluwarsa</b>                
        </b-alert>

        <!--
        <horizontal-stepper :steps="debSteps" 
                            @completed-step="completeStep"
                            @active-step="isStepActive"
                            @stepper-   ed="doSubmit"
        >                     
        </horizontal-stepper>
        -->

        <VStepTab :step.sync="stepTab" :last="stepLast" :config="stepValidate">
            <template slot="link">
                <VStepLink :step.sync="stepTab" :option="1">
                Informasi Perusahaan
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="2">
                Kontak PIC
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="3">
                Pengurus & Pemegang Saham
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="4">
                Penjamin Data
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="5">
                Dokumen
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="6">
                Rekening Bank & VA
                </VStepLink>
                <VStepLink :step.sync="stepTab" :option="7">
                Status
                </VStepLink>
            </template>
            <template slot="content">
                <div class="container" v-if="stepTab == 1">
                    <InfoPerusahaan/>
                </div>
                <div class="container" v-if="stepTab == 2">
                    <KontakPIC/>
                </div>
                <div class="container" v-if="stepTab == 3">
                    <Pengurus/>
                </div>
                <div class="container" v-if="stepTab == 4">
                    <Penjamin/>
                </div>
                <div class="container" v-if="stepTab == 5">
                    <Dokumen/>
                </div>
                <div class="container" v-if="stepTab == 6">
                    <Rekening/>
                </div>
                <div class="container" v-if="stepTab == 7">
                    <Status/>
                </div>

            </template>
            <template slot="action">
                <ul role="menu" aria-label="Pagination">
                    <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>
                    <li v-if="stepTab!=stepLast" aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" class="btn btn-primary" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                    <li aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-success" role="menuitem">Save <i class="icon-checkmark3 ml-2"></i></button></li>
                </ul>
            </template>
        </VStepTab>
    </b-card-body>
</b-card>

</template>

<script>
// import HorizontalStepper from 'vue-stepper'
 
import InfoPerusahaan from './InfoPerusahaan.vue'
import Pengurus from './Pengurus.vue'
import KontakPIC from './KontakPIC.vue'
import Penjamin from './Penjamin.vue'
import Dokumen from './Dokumen.vue'
import Rekening from './Rekening.vue'
import Status from './Status.vue'
import Gen from '@/libs/Gen.js'
import VStepLink from '@/components/VStep/VStepLink'
import VStepTab from '@/components/VStep/VStepTab'

export default{
    components: {
        InfoPerusahaan,
        Pengurus,
        KontakPIC,
        Penjamin,
        Dokumen,
        Rekening,
        Status,
        VStepLink,
        VStepTab
    },
    props:{
        row:Object,
        Config:Object
    },
    computed:{
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$refs
            }
        }
    },
    data(){
        return {
            stepTab:1,
            stepLast:7,
        }
    },
    methods: {
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$children[0].$children[7].$refs['VFormInfo'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else if(this.stepTab == 2){
                    this.$children[0].$children[7].$refs['VFormPIC'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else if(this.stepTab == 3){
                    let keyPerson = 0
                    let data = this.row
                    data.dpsData = this.$parent.dpsMR

                    Array.from(data.dpsData).forEach(item => {
                        if(item.dps_is_pengurus_key_person == "Y"){
                            keyPerson = keyPerson + 1
                        }
                    })

                    if(keyPerson <= 1){
                        this.$children[0].$children[7].$refs['VFormPengurus'].validate().then(success => {
                            if(success){
                                console.log("got it")
                                this.stepTab = this.stepTab +1
                                console.log("unreach")                            
                            }
                        })
                    } else{
                        return this.$swal({
                            icon: 'error',
                            title: "Hanya Boleh 1 Keyperson dalam data debitur"
                        })
                    }
                }else if(this.stepTab == 4){
                    this.$children[0].$children[7].$refs['VFormPenjamin'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$children[0].$children[7].$refs['VFormDokumen'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else if(this.stepTab == 6){
                    this.$children[0].$children[7].$refs['VFormRekening'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else if(this.stepTab == 7){
                    this.$children[0].$children[7].$refs['VFormStatus'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab +1
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
       
        doSubmit(){
            let data = this.row
            data.type = this.$parent.isAdd?'add':'update'
            data.dpsData = this.$parent.dpsMR
            data.dkpData = this.$parent.dkpMR
            data.dapData = this.$parent.dapMR
            data.ddpData = this.$parent.ddpMR
            data.dbData = this.$parent.dbMR
            data.dpeData = this.$parent.dpeMR
            data.dp_last_step = this.stepTab                           
            
            console.log(this.$children)

            if(this.stepTab == 1){
                this.$children[0].$children[7].$refs['VFormInfo'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 2){
                this.$children[0].$children[7].$refs['VFormPIC'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 3){
                let keyPerson = 0
                Array.from(data.dpsData).forEach(item => {
                    if(item.dps_is_pengurus_key_person == "Y"){
                        keyPerson = keyPerson + 1
                    }
                })
                if(keyPerson <= 1){
                    this.$children[0].$children[7].$refs['VFormPengurus'].validate().then(success => {
                        if(success){
                            this.doPost(data)
                        }
                    })
                } else{
                    return this.$swal({
                        icon: 'error',
                        title: "Hanya Boleh 1 Keyperson dalam data debitur"
                    })
                }
            }else if(this.stepTab == 4){
                this.$children[0].$children[7].$refs['VFormPenjamin'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 5){
                this.$children[0].$children[7].$refs['VFormDokumen'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 6){
                this.$children[0].$children[7].$refs['VFormRekening'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 7){
                this.$children[0].$children[7].$refs['VFormStatus'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else{
                console.log("no action")
            }
            
            
            
        },
        doPost(data){
            this.$parent.loadingOverlay = true           
            return Gen.apiRest('/do/BoDebiturPerusahaan', {data: data}, 'POST').then(res=>{
                let data = res.data
                if(data.status == 'success'){
                    this.$parent.doSetAlertForm(data)
                    this.$router.push({name:this.$route.name})
                    this.row = {}
                }
                this.$parent.loadingOverlay = false
            }).catch(err=>{
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                    err.message = err.response?.data?.message                                    
                    err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
                this.$parent.loadingOverlay = false
            })
        }
    },
    // mounted(){
    //     this.loadingOverlay = true
    //     setTimeout(() => {
    //         this.stepTab = this.row.dp_last_step || 1

    //         this.loadingOverlay = false
    //      }, 700)
    // },
    // watch: {
    //     $route(){
    //        this.loadingOverlay = true
    //         setTimeout(() => {
    //             this.stepTab = this.row.dp_last_step || 1

    //             this.loadingOverlay = false
    //         }, 700) 
    //     }
    // }
}
</script>