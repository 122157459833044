<template>
    <fieldset>
        <validation-observer
            ref="VFormPengurus"
        >
        <div class="heading_line">
            <h4>Informasi Pengurus & Pemegang Saham</h4>
        </div>
        
        <template v-if="(isParent.$parent.dpsMR||[]).length">
            <div v-for="(v,k) in isParent.$parent.dpsMR" :key="k">
                <div class="box_upload_img">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Sapaan<small v-if="isParent.$parent.dpsMR[k]['dps_is_pengurus_key_person'] == 'Y'" class="txt_mandatory">*</small></label>
                                        <div class="form-group">
                                            <v-select placeholder="Pilih Sapaan" :options="Config.mr.mrSapaan" label="text" v-model="isParent.$parent.dpsMR[k]['dps_sapaan']" :clearable="true" :reduce="v=>v.value"></v-select>
                                            <VValidate 
                                                :name="'Sapaan '+(k+1)" 
                                                v-model="isParent.$parent.dpsMR[k]['dps_sapaan']" 
                                                :rules="{required: isParent.$parent.dpsMR[k]['dps_is_pengurus_key_person'] == 'Y' ? 1 : 0}"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="form-group">
                                        <label>Nama Lengkap <small v-if="isParent.$parent.dpsMR[k]['dps_is_pengurus_key_person'] == 'Y'" class="txt_mandatory">*</small> </label>
                                        <input v-model="isParent.$parent.dpsMR[k]['dps_fullname']" type="text" name="name" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dps_fullname','dpsMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb"  class="form-control" placeholder="Anggraeni Fatmawati">
                          
                                        <VValidate 
                                            :name="'Nama Lengkap '+(k+1)" 
                                            v-model="isParent.$parent.dpsMR[k]['dps_fullname']" 
                                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required: isParent.$parent.dpsMR[k]['dps_is_pengurus_key_person'] == 'Y' ? 1 : 0, min:3, max: 60}"
                                        />
                          
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Jabatan</label>
                                        <input v-model="isParent.$parent.dpsMR[k]['dps_jabatan']" type="text" name="name" class="form-control" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dps_jabatan','dpsMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" placeholder="e.g. Direktur">

                                        <VValidate 
                                            :name="'Jabatan '+(k+1)" 
                                            v-model="isParent.$parent.dpsMR[k]['dps_jabatan']" 
                                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                        />
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Jumlah Saham </label>
                                        <div class="input-group">
                                            <input v-model="isParent.$parent.dpsMR[k]['dps_jml_saham_percentage']" type="text" class="form-control" placeholder="30">
                                            <span class="input-group-prepend">
                                                <span class="input-group-text">%</span>
                                            </span>
                                        </div>
                                        <VValidate 
                                            :name="'Jumlah Saham '+(k+1)" 
                                            v-model="isParent.$parent.dpsMR[k]['dps_jml_saham_percentage']" 
                                            :rules="{regex:/^[0-9.]+$/, max_value:100}"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label>Jumlah saham dalam rupiah</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                                <span class="input-group-text">Rp</span>
                                            </span>
                                            <b-form-input type="text" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="isParent.$parent.dpsMR[k]['dps_jml_saham']" class="form-control" placeholder="500.000.000">
                                            </b-form-input>

                                        </div>
                                        <VValidate 
                                            :name="'Jumlah saham dalam rupiah '+(k+1)" 
                                            v-model="isParent.$parent.dpsMR[k]['dps_jml_saham']" 
                                            :rules="{regex:/^[0-9\.]+$/}" 
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email </label>
                                        <input type="email" name="email" v-model="isParent.$parent.dpsMR[k]['dps_email']" class="form-control" placeholder="your@email.com">
                                        <VValidate 
                                            :name="'Email '+(k+1)" 
                                            v-model="isParent.$parent.dpsMR[k]['dps_email']" 
                                            :rules="{email:1, min: 3}" 
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>No Telepon Seluler </label>
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                                <span class="input-group-text">+62</span>
                                            </span>
                                            <input type="text" v-model="isParent.$parent.dpsMR[k]['dps_no_phone']" class="form-control" placeholder="">
                                            <VValidate 
                                                :name="'No Telepon Seluler '+(k+1)" 
                                                v-model="isParent.$parent.dpsMR[k]['dps_no_phone']" 
                                                :rules="{min:3, max: 13, numeric: 1}"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="col-md-4">
                            <div class="wrap_wizard_add bor_left">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>No KTP</label>
                                            <input type="text" name="name" class="form-control" placeholder="3174096112900001" v-model="isParent.$parent.dpsMR[k]['dps_no_ktp']">
                                            
                                            <VValidate 
                                                :name="'No KTP '+(k+1)" 
                                                v-model="isParent.$parent.dpsMR[k]['dps_no_ktp']" 
                                                :rules="{min:16, max: 16, numeric: 1}"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>File KTP </label>
                                            <Uploader v-model="isParent.$parent.dpsMR[k]['dps_file_ktp']" type="ktp"/>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>No NPWP</label>
                                            <input type="text" v-model="isParent.$parent.dpsMR[k]['dps_no_npwp']" name="name" class="form-control" placeholder=" 99.999.999.9-999.999">
                                            
                                            <VValidate 
                                                :name="'No NPWP '+(k+1)" 
                                                v-model="isParent.$parent.dpsMR[k]['dps_no_npwp']" 
                                                :rules="{min:15, max: 20, numeric: 1}"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>File NPWP </label>
                                            <Uploader v-model="isParent.$parent.dpsMR[k]['dps_file_npwp']" type="npwp"/>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                    </div>
                    <div class="wrap_wizard_add mt-4">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Provinsi</label>
                                <div class="form-group">
                                    <v-select placeholder="Pilih Provinsi" @input="doInputKab(k)"  v-model="isParent.$parent.dpsMR[k]['dps_prov']" :options="isParent.$parent.mrProvince"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Kabupaten/Kota</label>
                                <div class="form-group">
                                    <div class="form-group">
                                        <v-select placeholder="Pilih Kab/Kota" :options="isParent.$parent.mrCityP" label="text" @input="doInputKec(k)" v-model="isParent.$parent.dpsMR[k]['dps_kab']"  :clearable="true" :reduce="v=>v.value"></v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Kecamatan</label>
                                <div class="form-group">
                                    <div class="form-group">
                                        <v-select placeholder="Pilih Kecamatan" :options="isParent.$parent.mrDistrictP" label="text" @input="doInputKel(k)" v-model="isParent.$parent.dpsMR[k]['dps_kec']" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Kelurahan</label>
                                <div class="form-group">
                                    <div class="form-group">
                                        <v-select placeholder="Pilih Kelurahan" :options="isParent.$parent.mrSDistrictP" label="text" :clearable="true" :reduce="v=>v.value" v-model="isParent.$parent.dpsMR[k]['dps_kel']"></v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Kode Pos</label>
                                <div class="form-group">
                                   <v-select placeholder="Pilih Kode Pos" :options="isParent.$parent.mrPostalCodeP" label="text" :clearable="true" :reduce="v=>v.value" v-model="isParent.$parent.dpsMR[k]['dps_postal_code']"></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Alamat Domisili Pengurus</label>
                                    <textarea rows="3" cols="3" v-model="isParent.$parent.dpsMR[k]['dps_alamat_domisili']" class="form-control" placeholder="Alamat Domisili Pengurus"></textarea>
                                    
                                    <VValidate 
                                        name="Alamat Domisili Pengurus" 
                                        v-model="isParent.$parent.dpsMR[k]['dps_alamat_domisili']" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 128}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Alamat KTP Pengurus</label>
                                    <textarea rows="3" cols="3" v-model="isParent.$parent.dpsMR[k]['dps_alamat_ktp']" class="form-control" placeholder="Alamat KTP Pengurus"></textarea>
                                    
                                    <VValidate 
                                        name="Alamat KTP Pengurus" 
                                        v-model="isParent.$parent.dpsMR[k]['dps_alamat_ktp']" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 128}"
                                    />
                                </div>
                            </div>
                            
                            <!--
                            <div class="col-md-6">
                                <b-form-checkbox
                                    value="Y"
                                    unchecked-value="N"
                                    @input="sameAddress(k)"
                                    v-model="isParent.$parent.dpsMR[k]['dps_is_same_address']"
                                    >
                                    Alamat KTP sama dengan Domisili
                                </b-form-checkbox>
                            </div>
                            -->

                            <div class="col-md-4 mt-4">
                                <b-form-checkbox
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="isParent.$parent.dpsMR[k]['dps_is_pengurus_key_person']"
                                    >
                                    Pengurus adalah keyperson
                                </b-form-checkbox>
                            </div>
                            <div class="col-md-4 mt-4">
                                <b-form-checkbox
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="isParent.$parent.dpsMR[k]['dps_is_pengurus_signer']"
                                    >
                                    Pengurus adalah Signer
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="wrap_wizard_add mt-5">
                        <div class="row mb-4" v-for="(v2,k2) in isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas']" :key="k2">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Kode Identitas Pengurus</label>
                                        <v-select placeholder="Pilih Kode Identitas" v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['jenis_identitas']" :options="Config.mr.IdenPengurus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                </div>
                                <div class="form-group" v-if="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['jenis_identitas']=='Lainnya'">
                                    <label>Nama Kode Identitas</label>
                                    <input type="text" name="name" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dps_no_identitas','dpsMR',k)" v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['nama_identitas']" class="form-control" placeholder="Nama Kode Identitas">
                                    
                                    <VValidate 
                                        name="Nama Kode Identitas" 
                                        v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['nama_identitas']" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>No. Identitas</label>
                                    <input type="text" name="name" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dps_no_identitas','dpsMR',k)" v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['no_identitas']" class="form-control" placeholder="16 digit No. Identitas">
                                    
                                    <VValidate 
                                        name="No. Identitas" 
                                        v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['no_identitas']" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, required: 1}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>File</label>
                                    <Uploader isDocument v-model="isParent.$parent.dpsMR[k]['dps_kelengkapan_identitas'][k2]['file_identitas']" type="npwp"/>                                            
                                </div>
                            </div>
                            <button type="button" @click="removeRowIdentitas(k,k2)" class="btn text-danger mt-2 text-right ml-auto"><i class="icon-trash"></i> Hapus Kelengkapan Data</button>
                        </div>
                        <div class="row">
                        <button type="button" @click="addRowIdentitas(k)" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Kelengkapan Data</button>
                        </div>
                    </div>
                </div>
                <button type="button" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Pengurus</button>
                <button type="button" @click="removeRow(k)" class="btn text-danger mt-2"><i class="icon-trash"></i> Hapus Pengurus</button>
            </div>
        </template>

        <template v-else>
            <button @click="addRow()" type="button" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Pengurus</button>
        </template>

        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        computed: {
            isParent() { return this.$parent.$parent }
        },
        data(){
            return {
                isNextStep : false,
                lastKey : []
            }
        },
        methods: {
            sameAddress(k){
                if(this.isParent.$parent.dpsMR[k].dps_is_same_address == "Y"){
                    this.isParent.$parent.dpsMR[k]['dps_alamat_ktp'] = this.isParent.$parent.dpsMR[k]['dps_alamat_domisili']
                }
            },
            addRowIdentitas(k){
                let payload = {
                    "no_identitas" : "",
                    "nama_identitas": "",
                    "jenis_identitas": null,
                    "file_identitas": null,
                }
                if(!this.isParent.$parent.dpsMR[k].dps_kelengkapan_identitas) {
                    this.isParent.$parent.dpsMR[k].dps_kelengkapan_identitas = []
                }
                this.isParent.$parent.dpsMR[k].dps_kelengkapan_identitas.push(payload)
            },
            removeRowIdentitas(k1,k2){
                this.isParent.$parent.dpsMR[k1].dps_kelengkapan_identitas.splice(k2,1)      
            },
            addRow(){
                let payload = {
                    dps_dp_id: this.isParent.row.dp_id||"add", 
                    dps_sapaan: "", 
                    dps_fullname: "", 
                    dps_jabatan: "",
                    dps_jml_saham: "",
                    dps_jml_saham_percentage: "",
                    dps_email: "",
                    dps_no_phone: "",
                    dps_no_ktp: "",
                    dps_file_ktp: "",
                    dps_no_npwp: "",
                    dps_file_npwp: "",
                    dps_no_identitas: "", 
                    dps_kode_pengurus: "", 
                    dps_prov: "", 
                    dps_kab: "", 
                    dps_kec: "", 
                    dps_kel: "", 
                    dps_postal_code: "", 
                    dps_is_pengurus_key_person: "N", 
                    dps_is_same_address: "N", 
                    dps_alamat_domisili: "", 
                    dps_alamat_ktp: "",
                    dps_is_pengurus_signer:"N",
                    dps_kelengkapan_identitas: [],
                }
                this.isParent.$parent.dpsMR.push(payload)
            },
            removeRow(k){
                this.isParent.$parent.dpsMR.splice(k,1)      
            },
            inpKey(key){
                let dataParent = this.isParent.$parent.dpsMR
                Array.from(dataParent).forEach((item,index) => {
                    this.isParent.$parent.dpsMR[index]['dps_is_pengurus_key_person'] = 'N'            
                })
                this.isParent.$parent.dpsMR[key]['dps_is_pengurus_key_person'] = 'Y'            
            },
            doInputKab(k){
                this.isParent.$parent.mrCityP = []
                this.isParent.$parent.mrDistrictP = []
                this.isParent.$parent.mrSDistrictP = []
                this.isParent.$parent.mrPostalCodeP = []
                
                this.isParent.$parent.dpsMR[k]['dps_kab'] = null 
                this.isParent.$parent.dpsMR[k]['dps_kec'] = null 
                this.isParent.$parent.dpsMR[k]['dps_kel'] = null 
                this.isParent.$parent.dpsMR[k]['dps_postal_code'] = null 

                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-city', prv: this.isParent.$parent.dpsMR[k]['dps_prov']}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCityP = res.data.data
                })
                
            },
            doInputKec(k){
                this.isParent.$parent.mrDistrictP = []
                this.isParent.$parent.mrSDistrictP = []
                this.isParent.$parent.mrPostalCodeP = []
                
                this.isParent.$parent.dpsMR[k]['dps_kec'] = null 
                this.isParent.$parent.dpsMR[k]['dps_kel'] = null 
                this.isParent.$parent.dpsMR[k]['dps_postal_code'] = null 

                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-district', prv: this.isParent.$parent.dpsMR[k]['dps_prov'], city: this.isParent.$parent.dpsMR[k]['dps_kab']}}, 'POST').then(res=>{
                    this.isParent.$parent.mrDistrictP = res.data.data
                })
            },
            doInputKel(k){
                this.isParent.$parent.mrSDistrictP = []
                this.isParent.$parent.mrPostalCodeP = []
                
                this.isParent.$parent.dpsMR[k]['dps_kel'] = null 
                this.isParent.$parent.dpsMR[k]['dps_postal_code'] = null 
                
                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-sub-district', prv: this.isParent.$parent.dpsMR[k]['dps_prov'], city: this.isParent.$parent.dpsMR[k]['dps_kab'], district: this.isParent.$parent.dpsMR[k]['dps_kec']}}, 'POST').then(res=>{
                    this.isParent.$parent.mrSDistrictP = res.data.data
                })
                
                Gen.apiRest('/do/BoDebiturPerusahaan', {data: {type:'select-postal-code', prv: this.isParent.$parent.dpsMR[k]['dps_prov'], city: this.isParent.$parent.dpsMR[k]['dps_kab'], district: this.isParent.$parent.dpsMR[k]['dps_kec']}}, 'POST').then(res=>{
                    this.isParent.$parent.mrPostalCodeP = res.data.data
                })
            }
        },
    }
</script>