<template>
    <fieldset>
        <validation-observer
            ref="VFormPenjamin"
        >
        <template v-if="(isParent.$parent.dpeMR||[]).length">
            <div class="wrap_wizard_add" v-for="(v,k) in isParent.$parent.dpeMR" :key="k">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>NIK / No. KTP</label>
                            <input type="text" v-model="isParent.$parent.dpeMR[k].dpe_nik" name="name" class="form-control" placeholder="16 digit NIK">
                            
                            <VValidate 
                                name="NIK / No. KTP" 
                                v-model="isParent.$parent.dpeMR[k].dpe_nik" 
                                :rules="{min:16, max:16, numeric:1}"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Sapaan<small class="txt_mandatory">*</small></label>
                            <div class="form-group">
                                <v-select placeholder="Pilih Sapaan" :options="Config.mr.mrSapaan" label="text" v-model="isParent.$parent.dpeMR[k].dpe_sapaan" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    name="Sapaan" 
                                    v-model="isParent.$parent.dpeMR[k].dpe_sapaan" 
                                    :rules="{required:1}"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Nama Lengkap<small class="txt_mandatory">*</small></label>
                            <input type="text" v-model="isParent.$parent.dpeMR[k].dpe_nama" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dpe_nama','dpeMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" name="name" class="form-control" placeholder="Anggraeni Fatmawati">

                            <VValidate 
                                name="Nama Lengkap" 
                                v-model="isParent.$parent.dpeMR[k].dpe_nama" 
                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max:128}"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>No Handphone </label>
                            <div class="input-group">
                                <span class="input-group-prepend">
                                    <span class="input-group-text">+62</span>
                                </span>
                                <input v-model="isParent.$parent.dpeMR[k].dpe_no_hp" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dpe_no_hp','dpeMR',k)" type="text" class="form-control" placeholder="+99-999-9999-9999" data-mask="+99-999-9999-9999">

                                <VValidate 
                                    name="No Handphone" 
                                    v-model="isParent.$parent.dpeMR[k].dpe_no_hp" 
                                    :rules="{min:3, max:16, numeric: 1}"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Email address</label>
                            <input v-model="isParent.$parent.dpeMR[k].dpe_email" type="email" name="email" class="form-control" placeholder="your@email.com">
                            <VValidate 
                                name="Email" 
                                v-model="isParent.$parent.dpeMR[k].dpe_email" 
                                :rules="{min:3, max:16, email: 1}"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Jenis Penjamin</label>
                            <v-select placeholder="Jenis Penjamin" :options="Config.mr.mrJenisPenjamin" label="text" v-model="isParent.$parent.dpeMR[k].dpe_jenis_penjamin" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="">Alamat Penjamin</label>
                            <textarea v-model="isParent.$parent.dpeMR[k].dpe_alamat_penjamin" @input="isParent.$parent.removeMultiSpaceDebArr($event,'dpe_alamat_penjamin','dpeMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb"  name="" id="" rows="4" class="form-control" placeholder="e.g. Jl. Apel No. 17"></textarea>
                            
                            <VValidate 
                                name="Alamat Penjamin" 
                                v-model="isParent.$parent.dpeMR[k].dpe_alamat_penjamin" 
                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max:256}"
                            />
                        </div>
                    </div>
                </div>
                <button type="button" @click="addRow()" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Data Penjamin</button>
                <button type="button" @click="removeRow(k)" class="btn text-danger mt-2"><i class="icon-trash"></i> Hapus Data Penjamin</button>
            </div>
        </template>

        <template v-else>
            <button @click="addRow()" type="button" class="btn btn-outline alpha-purple text-purple-800 mt-2"><i class="icon-plus3"></i> Tambah Data Penjamin</button>
        </template>

        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        data(){
            return {
                isNextStep : false
            }
        },
        computed: {
            isParent() { return this.$parent.$parent }
        },
        methods: {
            addRow(){
                let payload = {
                    dpe_debitur_id: this.isParent.row.dp_id||"add", 
                    dpe_type: "CORPORATE", 
                    dpe_nik: "", 
                    dpe_sapaan: "",
                    dpe_nama: "",
                    dpe_no_hp: "",
                    dpe_email: "",
                    dpe_jenis_penjamin: "",
                    dpe_alamat_penjamin: ""
                }
                
                this.isParent.$parent.dpeMR.push(payload)
            },
            removeRow(k){
                this.isParent.$parent.dpeMR.splice(k,1)      
            }
        },
    }
</script>